@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300italic,300,400italic,600italic,600,700,700italic,800,800italic&subset=latin,latin-ext);

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing   : border-box;
    box-sizing        : border-box;

    &:after,
    &:before {
        -webkit-box-sizing: border-box;
        -moz-box-sizing   : border-box;
        box-sizing        : border-box;
    }
}

body,
html {
    font-family: 'Open Sans', sans-serif;
}
@media screen and (max-width: 768px) {
    .page_wrapper {
        padding: 30px !important;
    }
}

#global_wrapper {
    max-width: 1600px;
    margin   : 0 auto;
}

#main_header {
    position  : fixed;
    z-index   : 8;
    top       : 0;
    width     : 100%;
    max-width : 1600px;
    padding   : 10px 0 0;
    background: url("../img/template/header_strap_bg.jpg") repeat-x center top;

    .container {
        position: relative;
    }

    .header_bar {
        min-height: 100px;
        background: #00a0e4;
    }
}

#logo {
    display: block;
    margin : 15px 0 0;
}

div.wpcf7-response-output {
    padding      : 0.75em 1em !important;
    text-align   : center;
    border-radius: 4px;
}

.send_file {
    display                    : block;
    width                      : 140px;
    height                     : 40px;
    margin                     : 30px 0 0;
    padding                    : 6px 0 0 27px;
    -webkit-transition-duration: 0.5s;
    transition-duration        : 0.5s;
    text-align                 : center;
    color                      : #fff;
    border                     : 2px solid #fff;
    border-radius              : 5px;
    background                 : url('../img/icon/send_file.png') no-repeat 5px 7px;
    font-size                  : 16px;

    &.contact {
        width: 140px !important;

        span {
            visibility: visible !important;
        }
    }

    &:focus,
    &:hover {
        text-decoration: none;
        color          : #00A0E4;
        background     : #fff url('../img/icon/send_file_blue.png') no-repeat 5px 7px;
    }

    img {
        margin: -2px 10px 0 0;
    }
}

#top_menu {
    margin: 30px 0 0;

    > ul.main_menu > li {
        position     : relative;
        display      : block;
        border-radius: 5px;

        > a {
            padding  : 12px 14px;
            color    : #fff;
            font-size: 16px;
        }

        &:hover {
            background: #008ddf;

            .sub_menu_wrapper {
                visibility: visible;
            }
        }

        > a {
            &:focus,
            &:hover {
                border-radius: 5px;
                background   : #008ddf;
            }
        }
    }
}

.sub_menu_wrapper {
    position   : absolute;
    left       : 50%;
    visibility : hidden;
    width      : 220px;
    margin-left: -110px;
}

.arrow-up {
    width        : 0;
    height       : 0;
    margin       : 0 auto;
    border-right : 10px solid transparent;
    border-bottom: 10px solid black;
    border-left  : 10px solid transparent;
}

.sub_menu {
    margin    : 0;
    padding   : 11px 0;
    list-style: none;
    background: #f2f3f7;
}

.sub_menu_wrapper .arrow-up {
    margin-bottom      : -1px;
    border-bottom-color: #f2f3f7;
}

.sub_menu > li {
    padding   : 0 20px;
    border-top: 1px solid #dadce5;
    background: #f2f3f7;

    &:first-child {
        border-top: none;
    }

    > a {
        display        : block;
        padding        : 13px 0;
        text-decoration: none;
        color          : #647075;
        font-size      : 16px;

        &:hover {
            color: #405660;
        }
    }

    &:hover {
        background: #fff;
    }
}

.menu_button {
    display      : none;
    width        : 40px;
    height       : 40px;
    cursor       : pointer;
    border       : 2px solid #fff;
    border-radius: 5px;
    background   : #00A0E4 url("../img/icon/rwd-menu.png") no-repeat center;

    &:hover {
        background: #008ddf url("../img/icon/rwd-menu.png") no-repeat center;
    }
}

.header_buttons {
    float: right;

    &.file {
        margin-right: 20px;
    }
}

.arrow {
    position  : absolute;
    top       : 50%;
    right     : 40px;
    display   : none;
    float     : right;
    width     : 16px;
    height    : 9px;
    margin-top: -4px;
}

.down_arrow {
    background: url("../img/icon/down_arrow.png") no-repeat right top;
}

.up_arrow {
    background: url("../img/icon/up_arrow.png") no-repeat right top;
}

.right_arrow {
    width     : 9px;
    height    : 16px;
    background: url("../img/icon/right_arrow.png") no-repeat right top;
}

.offer_arrow {
    display       : inline-block;
    width         : 9px;
    height        : 16px;
    vertical-align: middle;
    background    : url("../img/icon/offer_arrow.png") no-repeat right top;
}

.hvr-grow {
    display                : inline-block;
    transition-duration    : 0.3s;
    transition-property    : transform;
    transform              : translateZ(0);
    vertical-align         : middle;
    box-shadow             : 0 0 1px rgba(0, 0, 0, 0);
    backface-visibility    : hidden;
    -moz-osx-font-smoothing: grayscale;

    &:active,
    &:focus,
    &:hover {
        /* IE 9 */
        -webkit-transform: translate(15px, 0px);
        -ms-transform    : translate(15px, 0px);
        /* Safari */
        transform        : translate(15px, 0px);
    }
}
/*
SECTION HOME
*/
#top_section {
    padding-bottom: 0.1px;
    background    : url("../img/template/home_strap_bg.jpg") no-repeat center bottom;
}

.top_wrapper {
    position             : relative;
    z-index              : 0;
    min-height           : 700px;
    padding              : 304px 0 0;
    background-image     : url("../img/header.jpg");
    background-attachment: fixed;
    background-position  : 50% 0;
}

.top_section_content {
    h1,
    h3 {
        margin    : 0;
        text-align: center;
        color     : #fff;
    }

    h1 {
        margin     : 0 0 27px;
        font-size  : 48px;
        font-weight: 700;
    }

    h3 {
        font-size  : 24px;
        font-weight: 300;
        font-style : italic;
    }
}

.slide_section {
    position   : absolute;
    bottom     : 50px;
    left       : 50%;
    width      : 40px;
    height     : 40px;
    margin-left: -20px;
    cursor     : pointer;
    background : url("../img/icon/slide_down.png") no-repeat center top;
}
/* SEKCJE */
.section_header {
    margin        : 30px 0 20px;
    text-align    : center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color         : #a8aab5;
    font-size     : 14px;
    font-weight   : 900;
}
/* ZAMĂ“W DRUK 3D */
#order_print {
    padding: 0 0 30px;
}

.order_steps_wrapper {
    padding: 0 0 35px;
}

.order_steps {
    position  : relative;
    padding   : 11px 0 17px;
    text-align: center;

    &:hover {
        background: #fff;
        /*box-shadow: 1px 1px 5px #e9eaee;*/
        box-shadow: 1px 1px 5px #e5e6e6;
    }

    header {
        padding    : 0 0 18px;
        color      : #405660;
        font-size  : 24px;
        font-weight: 300;
    }
}

.step_icon {
    width : auto;
    height: 180px;
}

.order_steps_description {
    margin   : 0 0 25px;
    padding  : 0 48px;
    color    : #647075;
    font-size: 16px;
}

.step_icon {
    &.add_file {
        margin-left: -45px;
        background : url("../img/icon/add_file.png") no-repeat center top;
    }

    &.print_price {
        margin-left: -45px;
        background : url("../img/icon/print_price.png") no-repeat center top;
    }

    &.print_monitor {
        margin-left: -45px;
        background : url("../img/icon/print_monitor.png") no-repeat center top;
    }
}

.step_button {
    display                    : inline-block;
    margin                     : 12px 0 20px;
    padding                    : 7px 22px;
    -webkit-transition-duration: 0.2s;
    transition-duration        : 0.2s;
    color                      : #00a0e4;
    border                     : 2px solid #00a0e4;
    border-radius              : 5px;
    font-size                  : 16px;

    &:hover {
        text-decoration: none;
        color          : #fff;
        background     : #00a0e4;
    }
}

.step_link {
    display   : block;
    clear     : both;
    text-align: center;
    color     : #00a0e4;
    font-size : 16px;

    &:hover {
        display   : block;
        clear     : both;
        text-align: center;
        color     : #00a0e4;
        font-size : 16px;
    }
}
/* Realizacje WydrukĂłw 3D */
.gallery_item,
.grid_item {
    position: relative;
    padding : 0;
}

.grid_item > a {
    display: block;
}

.portfolio_item_wrapper {
    display   : inline-block;
    text-align: center;
    font-size : 0;

    .grid_item {
        float     : none;
        width     : 100%;
        max-width : 400px;
        text-align: left;
    }
}

.grid_hover {
    position                   : absolute;
    top                        : 0;
    left                       : 0;
    visibility                 : hidden;
    width                      : 100%;
    height                     : 100%;
    -webkit-transition-duration: 0.7s;
    transition-duration        : 0.7s;
    opacity                    : 0;
    background                 : rgba(0, 160, 228, 0.7);
}

.realization_desc {
    position: absolute;
    bottom  : 60px;
    left    : 0;
    padding : 0 0 0 16px;
}

.realization_category,
.realization_name {
    color      : #fff;
    font-weight: 300;
}

.realization_name {
    font-size: 24px;
}

.realization_category {
    margin   : 0;
    font-size: 16px;
}

.realization_arrow {
    position: absolute;
    bottom  : 10px;
    left    : 16px;
}

.grid_item {
    > a:hover .grid_hover {
        visibility: visible;
        opacity   : 1;
    }

    img {
        width : 100%;
        height: 100%;
        margin: 0 auto;
    }
}
/* OFERTA */
#offer {
    padding: 48px 0;
}

.offer_items {
    box-sizing   : content-box;
    padding      : 0;
    color        : #647075;
    border-top   : 5px solid #fff;
    border-bottom: 5px solid #fff;
    background   : #f2f3f7;
}

.offer_items_content {
    padding: 20px 40px;
}

.offer_items {
    &:hover {
        position     : relative;
        z-index      : 2;
        border-top   : 5px solid #fff;
        border-bottom: 5px solid #fff;
        background   : #fff;
        box-shadow   : 0 0 5px #e5e6e6;
    }

    .rwd_menu_separator {
        display: none;
    }
}

.order_steps .rwd_menu_separator {
    display: none;
}

.offer_icon,
.offer_name {
    display       : inline-block;
    vertical-align: middle;
}

.offer_icon {
    width : 60px;
    height: 60px;

    &.technology {
        background: url("../img/icon/technology.png") no-repeat center top;
    }

    &.cad {
        background: url("../img/icon/cad.png") no-repeat center top;
    }

    &.questions {
        background: url("../img/icon/questions.png") no-repeat center top;
    }
}

.offer_name {
    margin     : 0 0 0 16px;
    font-size  : 24px;
    font-weight: 300;
}

.offer_desc {
    padding  : 13px 0 18px;
    font-size: 16px;
}

.offer_link {
    display        : block;
    text-decoration: none;
    color          : #00a0e4;
    font-size      : 16px;

    &:hover {
        display        : block;
        text-decoration: none;
        color          : #00a0e4;
        font-size      : 16px;
    }

    > span {
        display       : inline-block;
        padding       : 0 20px 0 0;
        vertical-align: middle;
    }
}
/* SEKCJA WYĹšLIJ PLIK */
#send_file_section {
    min-height           : 370px;
    padding              : 86px 0 0;
    background-image     : url("../img/send_file_section.jpg");
    background-attachment: fixed;
    background-position  : 50% 0;

    .container h1 {
        margin     : 0 0 40px;
        text-align : center;
        color      : #fff;
        font-size  : 48px;
        font-weight: 700;
    }

    .send_file {
        width              : 170px;
        margin             : 0 20px 0 0;
        background-position: 18px 7px;

        &:hover {
            color     : #fff;
            background: rgba(0, 0, 0, 0.8) url("../img/icon/send_file.png") no-repeat 18px 7px;
        }
    }
}
/* STOPKA */
footer {
    padding: 30px 0 0;
}

#main_footer {
    background: #f2f3f7;
}

.footer_menu {
    margin    : 0;
    padding   : 9px 0 10px;
    list-style: none;
    text-align: center;

    > li {
        display: inline-block;

        > a {
            display        : block;
            padding        : 11px 15px;
            text-decoration: none;
            color          : #647075;
            font-size      : 16px;
        }
    }
}

#footer_rights {
    padding   : 19px 0;
    background: url("../img/template/footer_rights_bg.jpg") repeat center top;
}

.fr_container {
    padding: 0 40px;
}

.footer_logo {
    display       : inline-block;
    width         : 27px;
    height        : 41px;
    vertical-align: middle;
    background    : url("../img/template/footer_logo.png") no-repeat center top;
}

.fr_right {
    margin-top: 10px;
}

.created,
.rights {
    display       : inline-block;
    margin        : 0 0 0 9px;
    vertical-align: middle;
    color         : #fff;
    font-size     : 12px;
}

.rights p {
    margin: 0;
}

.created_by {
    display       : inline-block;
    vertical-align: middle;
}
/* Strona O Nas */
.page_title {
    margin     : 57px 0 20px;
    color      : #405660;
    font-size  : 24px;
    font-weight: 300;
}

.page_wrapper {
    padding: 40px 50px;
}

.page_description {
    text-align: justify;
    color     : #647075;
    font-size : 16px;

    p {
        margin-bottom: 20px;

        &.italic {
            font-style: italic;
        }
    }

    img {
        display: block;
        width  : 100%;
        height : 100%;
        margin : 20px auto;
    }
}

.pd_list {
    list-style: none;

    li:before {
        margin   : 0 12px 0 0;
        content  : url('../img/icon/dott.png');
        color    : #00a0e4;
        font-size: 18px;
    }
}

.gallery_arrow {
    position   : absolute;
    top        : 50%;
    left       : 50%;
    margin-top : -4px;
    margin-left: -8px;
}

#gallery {
    &.page {
        padding: 0 0 55px;
    }

    &.detail {
        padding: 0 0 25px;
    }
}

.sfs_nav {
    text-align: center;
    color     : #fff;

    > span {
        font-size: 16px;
    }

    .send_file {
        display: inline-block;
    }
}

.add_question_link {
    text-decoration: underline;
    color          : #fff;
    font-size      : 16px;

    &:hover {
        text-decoration: underline;
        color          : #fff;
        font-size      : 16px;
    }
}
/* Galerie zdjÄ™Ä‡ */
.gallery_thumb {
    display: block;
    width  : 100%;
    height : 100%;
}
/* Technologie */
.technology_wrapper {
    padding: 35px 0;
}

.technology_item {
    padding: 0 0 38px;

    &:hover {
        background: #fff;
        box-shadow: 0 1px 5px #e5e6e6;
    }
}

.technology_icon {
    display: block;
    margin : 70px auto 0;
}

.technology_name {
    padding    : 50px 0 17px;
    color      : #405660;
    font-size  : 24px;
    font-weight: 300;
}

.technology_desc {
    padding  : 0 0 25px;
    color    : #647075;
    font-size: 16px;
}

.technology_button {
    display                    : block;
    width                      : 280px;
    margin                     : 12px 0 20px;
    padding                    : 3px 0;
    -webkit-transition-duration: 0.2s;
    transition-duration        : 0.2s;
    text-align                 : center;
    text-decoration            : none;
    color                      : #00a0e4;
    border                     : 2px solid #00a0e4;
    border-radius              : 5px;
    font-size                  : 16px;

    &:hover {
        display        : block;
        width          : 280px;
        padding        : 3px 0;
        text-align     : center;
        text-decoration: none;
        color          : #fff;
        border         : 2px solid #00a0e4;
        border-radius  : 5px;
        background     : #00a0e4;
        font-size      : 16px;
    }
}

.technology_rating {
    padding: 36px 0 0;
}
/* Realizacje */
#portfolio {
    margin: 32px 0 53px;
}

.portfolio_wrapper {
    padding: 32px 0 0;

    .mix {
        display: none;
    }
}

.sort_options {
    margin    : 0;
    padding   : 0;
    list-style: none;
    text-align: center;

    > li {
        display: inline-block;

        > a {
            display        : block;
            width          : 115px;
            cursor         : pointer;
            text-decoration: none;
            color          : #00a0e4;
            border         : 2px solid #00a0e4;
            border-radius  : 5px;
            font-size      : 16px;
            line-height    : 40px;

            &:focus,
            &:hover {
                color     : #fff;
                border    : 2px solid #008ddf;
                background: #008ddf;
            }
        }

        &:first-child a {
            border: 2px solid #00a0e4;

            &:hover {
                color: #white;
            }
        }
    }

    .filter.active {
        color     : #fff;
        border    : 2px solid #008ddf;
        background: #008ddf;
    }
}
/* SzczegĂłĹ‚ Realizacji */
.section_header.detail {
    margin-bottom: 14px;
}

.header_hr {
    width     : 95%;
    height    : 1px;
    margin    : 0 auto;
    background: #dadce5;
}

.technology_wrapper.detail {
    padding-top   : 20px;
    padding-bottom: 0;
}

.portfolio_wrapper.detail {
    padding-bottom: 41px;
}
/* KONTAKT */
.contact_wrapper {
    padding  : 35px 0 0;
    font-size: 0;
}

.contact_left,
.contact_right {
    display       : inline-block;
    width         : 50%;
    vertical-align: top;
    color         : #405660;
    font-size     : 16px;
}

.contact_us {
    margin     : 0 0 20px;
    font-size  : 24px;
    font-weight: 300;
}

.contact_desc {
    color    : #647075;
    font-size: 16px;
}

.contact_number,
.contact_phone {
    display       : inline-block;
    vertical-align: middle;
}

.phone_wrapper {
    margin: 20px 0 0;
}

.contact_phone {
    width     : 60px;
    height    : 60px;
    background: url("../img/icon/phone.png") no-repeat center top;
}

.contact_number {
    padding-left: 12px;
    color       : #405660;
    font-size   : 24px;
}

.company_data {
    .header {
        margin     : 48px 0 22px;
        font-size  : 24px;
        font-weight: 300;
    }

    address {
        color    : #647075;
        font-size: 16px;
    }

    p {
        margin: 0;
    }

    address p {
        margin: 0;

        &:first-child {
            font-weight: 700;
        }
    }
}
/* FORMULARZ KONTAKTOWY */
.contact_form_wrapper {
    position  : relative;
    z-index   : 2;
    padding   : 32px 28px 22px;
    color     : #405660;
    background: #fff;
    box-shadow: 0 1px 5px #bababa;
}

.contact_form {
    margin: 18px 0 0;
}

.need_print {
    display: block;
    float  : left;
    margin : 3px 0 0;
}

.contact_file {
    margin       : 0 0 20px;
    padding      : 0 0 20px;
    border-bottom: 1px solid #dadce5;
}

.contact_form_wrapper .send_file {
    display   : block;
    float     : right;
    margin    : 0;
    color     : #00a0e4;
    border    : 2px solid #00a0e4;
    background: url("../img/icon/send_file_blue.png") no-repeat 5px 7px;

    &:hover {
        color     : #fff;
        background: #01A0E4 url("../img/icon/send_file.png") no-repeat 5px 7px;
    }
}

#file_cad,
#file_cad-A {
    display: none;
}

.contact_form_header {
    font-size  : 24px;
    font-weight: 300;
}

.contact_form {
    input,
    textarea {
        color     : #405660;
        background: #f2f3f7;
        font-size : 16px;
    }

    input {
        &[type=email],
        &[type=text] {
            width    : 100%;
            max-width: 370px;
        }

        &.red {
            border-width: 2px;
            border-color: #cd0808;
            background  : #fff;
        }
    }

    textarea.red {
        border-width: 2px;
        border-color: #cd0808;
        background  : #fff;
    }

    input.blue {
        border-color: #00a0e4;
        background  : #fff;
    }

    textarea {
        min-height: 130px;

        &.blue {
            border-color: #00a0e4;
            background  : #fff;
        }
    }
}

.contact_form_button {
    display      : block;
    width        : 180px;
    margin       : 17px auto 0;
    color        : #00a0e4;
    border       : 2px solid #00a0e4;
    border-radius: 5px;
    background   : #fff;
    line-height  : 40px;
}
/* MAPA */
#map {
    clear : both;
    width : 100%;
    height: 490px;
}

#contact_map {
    margin-top    : 0;
    padding-bottom: 20px;
}
/* MODAL wysyĹ‚anie plikĂłw */
.sendfile-wrapper {
    padding: 1em 1em 2em;
}

#sendFile {
    .modal-content {
        border-radius: 0;
    }

    .modal-dialog {
        max-width: 900px;
    }

    .modal-body {
        padding: 0;
    }
}

.file_modal_tabs {
    padding   : 10px 0 0;
    text-align: center;
    background: #f2f3f7;

    > li {
        display: inline-block;
        float  : none;

        > a {
            display      : block;
            width        : 155px;
            height       : 49px;
            color        : #99a4ab;
            border-radius: 0;
            font-size    : 16px;
        }

        &.active > a {
            color: #405660;
        }
    }
}

.load_graphic {
    position  : relative;
    width     : 245px;
    height    : 157px;
    margin    : 5px auto 15px;
    background: url("../img/icon/load_graphic.png") no-repeat center top;
}

.file_modal_wrapper {
    position: relative;
    padding : 0 0 35px;

    .send_file {
        width     : 210px;
        height    : 40px;
        margin    : 30px auto 0;
        color     : #00A0E4;
        border    : 2px solid #00A0E4;
        background: url("../img/icon/send_file_blue.png") no-repeat 12px 7px;

        &:hover {
            color     : #fff;
            background: #01A0E4 url("../img/icon/send_file.png") no-repeat 12px 7px;
        }
    }
}

.file_form_wrapper {
    padding: 0 75px;

    textarea {
        height    : 110px;
        border    : 1px solid #dadce5;
        background: #f2f3f7;
    }
}

.file_form_header {
    margin     : 29px 0 0;
    padding    : 16px 0 0;
    color      : #3F5763;
    border-top : 1px solid #dadce5;
    font-size  : 24px;
    font-weight: 300;

    p {
        text-align: center;
    }
}

.file_form {
    input {
        &[type=email],
        &[type=text] {
            height    : 40px;
            border    : 1px solid #dadce5;
            background: #f2f3f7;
        }

        &.red {
            border-width: 2px;
            border-color: #cd0808;
            background  : #fff;
        }
    }

    textarea.red {
        border-width: 2px;
        border-color: #cd0808;
        background  : #fff;
    }

    input.blue,
    textarea.blue {
        border-color: #00a0e4;
        background  : #fff;
    }
}

.file_button {
    display                    : block;
    width                      : 260px;
    height                     : 60px;
    margin                     : 0 auto;
    -webkit-transition-duration: 0.4s;
    transition-duration        : 0.4s;
    color                      : #00a0e4;
    border                     : 2px solid #00a0e4;
    border-radius              : 5px;
    background                 : #fff;
    font-size                  : 24px;

    &:hover {
        color     : #fff;
        background: #008ddf;
    }

    &[disabled] {
        cursor : no-drop;
        opacity: 0.4;
    }
}

.load_status {
    position: absolute;
    top     : 50%;
    left    : 50%;
    width   : 57px;
    height  : 57px;
    margin  : -28px 0 0 -28px;
}

.progress_value {
    display: none;
}

.load_status {
    &.insert {
        background: url("../img/icon/insert_file.png") no-repeat center top;
    }

    &.success {
        width     : 85px;
        height    : 63px;
        margin    : -31px 0 0 -42px;
        background: url("../img/icon/success_file.png") no-repeat center top;
    }

    &.progress {
        text-align   : center;
        color        : #fff;
        border-radius: 50%;
        background   : #00a0e4;
        font-size    : 16px;
        line-height  : 57px;

        .progress_value {
            display: block;
        }
    }
}

.progress_bar {
    width     : 100%;
    max-width : 210px;
    height    : 6px;
    margin    : 35px auto 0;
    background: #dadce5;
}

.progress_width {
    position  : relative;
    top       : -2px;
    height    : 10px;
    margin-top: -2px;
    background: #00a0e4;
}

.custom_select {
    width    : 260px;
    font-size: 16px;
}

.select2-container--open .select2-dropdown {
    left: 1px !important;
}

.select2-container .select2-selection--single {
    height: 40px;

    .select2-selection__rendered {
        font-size: 16px;
    }
}

.select2-container--default .select2-selection--single {
    border          : 1px solid #dadce5;
    background      : rgba(255, 255, 255, 1);
    background      : -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(237, 237, 237, 1) 91%, rgba(245, 240, 245, 1) 100%);
    background      : -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(91%, rgba(237, 237, 237, 1)), color-stop(100%, rgba(245, 240, 245, 1)));
    background      : -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(237, 237, 237, 1) 91%, rgba(245, 240, 245, 1) 100%);
    background      : -o-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(237, 237, 237, 1) 91%, rgba(245, 240, 245, 1) 100%);
    background      : -ms-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(237, 237, 237, 1) 91%, rgba(245, 240, 245, 1) 100%);
    background      : linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(237, 237, 237, 1) 91%, rgba(245, 240, 245, 1) 100%);
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=  '#ffffff', endColorstr='#f5f0f5', GradientType=0 );

    .select2-selection__rendered {
        line-height: 40px;
    }

    .select2-selection__placeholder {
        color      : #a8aab5;
        font-family: 'Open Sans', sans-serif;
        font-size  : 16px;
        font-weight: normal;
    }

    .select2-selection__arrow {
        height    : 40px;
        margin    : 0 5px 0 0;
        background: url("../img/icon/select_arrow.png") no-repeat center;

        b {
            display: none;
        }
    }
}

.select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 30px;
}

.form_label {
    width         : 100%;
    max-width     : 101px;
    vertical-align: middle;
    color         : #405660;
    font-size     : 16px;
    font-weight   : normal;
}

.rangeslider {
    display       : inline-block;
    width         : 214px;
    height        : 3px;
    vertical-align: middle;
    border-radius : 0;
}

.rangeslider__fill {
    position     : relative;
    top          : -1px;
    height       : 5px;
    border-radius: 0;
    background   : #00a0e4;
}

.rangeslider__handle {
    top       : -11px;
    width     : 25px;
    height    : 25px;
    border    : none;
    background: none;
    box-shadow: none;

    &::after {
        width           : 25px;
        height          : 25px;
        background-image: url("../img/icon/range_handle.png");
    }
}

.range_result {
    display       : inline-block;
    padding       : 0;
    vertical-align: middle;
    color         : #405660;
    font-size     : 16px;
}

.current_file_name,
.current_file_name-A {
    overflow     : hidden;
    margin       : 35px auto 0;
    padding      : 0 10px;
    text-align   : center;
    white-space  : nowrap;
    text-overflow: ellipsis;
    color        : #405660;
    font-size    : 16px;
}

.exit_modal {
    position: absolute;
    z-index : 30;
    top     : 10px;
    right   : 10px;
    display : block;
    width   : 20px;
    height  : 20px;
    cursor  : pointer;
}
/* STYLE GLOBALNE */
.vertical_border {
    position  : absolute;
    z-index   : 0;
    top       : 5%;
    right     : -1px;
    width     : 1px;
    height    : 90%;
    background: #d0d2da;
}

.vertical_border_a1 {
    position  : absolute;
    z-index   : 0;
    top       : 5%;
    right     : 0;
    width     : 1px;
    height    : 90%;
    background: #d0d2da;
}

.vertical_border_2,
.vertical_border_3 {
    position  : absolute;
    z-index   : 0;
    top       : 5%;
    right     : -1px;
    width     : 1px;
    height    : 90%;
    background: #d0d2da;
}

.modal-backdrop {
    background-color: #00a0e4;

    &.in {
        opacity: 0.8;
    }
}

.no_padding {
    padding: 0;
}

.top_wrapper.sub_page {
    min-height      : 340px;
    padding         : 166px 0 0;
    background-image: url("../img/header_subpage.jpg");
}

.gray_section {
    padding-bottom: 20px;
    background    : #F2F3F7;
}

.rating {
    background: rgba(0, 0, 0, 0) url("../img/icon/vote_dot.png") no-repeat scroll 0 -25px;

    &.empty {
        overflow: hidden;
        width   : 145px;
        height  : 25px;

        span {
            display: block;
            width  : 145px;
            height : 25px;
        }
    }

    &.vote {
        background-position: 0 0;
    }
}

.rating_container {
    margin   : 0 0 15px;
    font-size: 0;
}

.rating_name,
.rating_result {
    display       : inline-block;
    vertical-align: middle;
    font-size     : 14px;
}

.rating_name {
    width     : 55%;
    padding   : 0 15px 0 0;
    text-align: right;
}

.rating_result {
    width: 45%;
}

.upload-file_info {
    display    : none;
    padding-top: 25px;
}

.info {
    padding    : 30px;
    text-align : center;
    color      : #fff;
    background : #b2cd1f;
    font-size  : 16px;
    line-height: 22px;

    a {
        color: #000;
    }
}

.blad {
    padding    : 30px;
    text-align : center;
    color      : #fff;
    background : #ee8554;
    font-size  : 16px;
    line-height: 22px;

    a {
        color: #fff;
    }
}

.blad_txt {
    color: #d23207;
}

.social {
    display    : inline-block;
    margin     : 0 auto;
    text-align : center;
    white-space: nowrap;

    a {
        display        : inline-block;
        width          : 32px;
        height         : 32px;
        margin         : 4px 5px 0;
        text-decoration: none;

        i {
            display: block;
            width  : 32px;
            height : 32px;

            &.si-fb {
                background: url("/img/icon/social.png") 0 0 no-repeat;
            }

            &.si-fl {
                background: url("/img/icon/social.png") 0 -32px no-repeat;
            }

            &.si-tt {
                background: url("/img/icon/social.png") 0 -64px no-repeat;
            }
        }
    }
}
// FILE UPLOAD
$file-upload-color: #00a0e4;
$file-upload-size: 100%;

* {
    box-sizing: border-box;
}

.form-styled {
    margin: 10px;
}

.text-center {
    text-align: center !important;
}

input.wpcg7-text {
    padding  : 11px 17px;
    font-size: 16px;
}

.ginput_container input,
input.wpcf7-text,
textarea.wpcf7-textarea {
    padding   : 5px 10px;
    transition: all 0.5s ease-in-out;
    max-width : 100%;
    border    : 1px solid rgba($file-upload-color, .9);

    &:focus {
        border: 1px solid #00a0e4;
    }
}

.ginput_container select {
    padding   : 5px 10px;
    border    : 1px solid rgba($file-upload-color, .9);
    transition: all 0.5s ease-in-out;
    max-width : 100%;
}

#gform_1 {
    text-align: center;
}

.fields-form .wpcf7-text {
    width    : 100%;
    font-size: 16px;
    padding  : 11px 17px;
}

.submit-btn-wrapper {
    padding: 1em 0.5em;
}

.custom-file-upload-hidden {
    position  : absolute;
    left      : -9999px;
    display   : none;
    visibility: hidden;
}

.custom-file-upload {
    display   : block;
    width     : auto;
    margin-top: 30px;
    font-size : 16px;
    //border: 1px solid #ccc;
    label {
        display      : block;
        margin-bottom: 5px;
    }
}

.file-upload-wrapper {
    position     : relative;
    min-height   : 100px;
    margin-bottom: 5px;
    //border: 1px solid #ccc;
}

.file-upload-input {
    // @include transition(all 0.2s ease-in);
    float           : left;
    /* IE 9 Fix */
    width           : $file-upload-size;
    max-width       : 100%;
    padding         : 11px 17px;
    color           : #fff;
    border          : none;
    background-color: $file-upload-color;
    font-size       : 16px;

    &:focus,
    &:hover {
        outline         : none;
        background-color: darken($file-upload-color, 5);
    }
}

.file-upload-button {
    display         : inline-block;
    float           : left;
    /* IE 9 Fix */
    // margin-left     : -1px;
    width           : 100%;
    max-width       : 100%;
    padding         : 11px 20px;
    cursor          : pointer;
    text-transform  : uppercase;
    color           : #fff;
    border          : none;
    background-color: darken($file-upload-color, 10);
    font-size       : 27px;
    font-weight     : 600;
    transition      : 0.2 all ease-in-out;
    // @include transition(all 0.2s ease-in);
    &:hover {
        background-color: darken($file-upload-color, 20);
    }

    &.bigger {
        padding: 32px 20px;
    }
}

.accordion dl,
.accordion-list {
    border: 1px solid #ddd;

    &:after {
        display         : block;
        width           : 100%;
        height          : 1em;
        content         : "";
        background-color: darken(#00a0e4, 10%);
    }
}

.accordion dd,
.accordion__panel {
    background-color: #eee;
    font-size       : 1em;
    line-height     : 1.5em;
}

.accordion p {
    padding   : 2em 1em;
    text-align: center;
}

.accordion {
    position        : relative;
    background-color: #eee;
    margin          : 10px 0;
}

.accordionTitle,
.accordion__Heading {
    display         : block;
    padding         : 1em;
    transition      : background-color 0.5s ease-in-out;
    text-align      : center;
    text-decoration : none;
    color           : #fff;
    border-bottom   : 1px solid darken(#00a0e4, 5%);
    background-color: #00a0e4;
    text-decoration : none;
    font-weight     : 700;

    &:before {
        float      : left;
        //  display: none;
        content    : "+";
        transition : transform 0.3s ease-in-out;
        font-size  : 1.5em;
        line-height: 0.5em;
    }

    &:hover {
        background-color: darken(#00a0e4, 10%);
        text-decoration : none;
    }
}

.accordionTitle.is-expanded,
.accordionTitleActive {
    background-color: darken(#00a0e4, 10%);

    &:before {
        transform: rotate(-225deg);
    }
}

.accordionItem {
    overflow  : hidden;
    height    : auto;
    //SHAME: magic number to allow the accordion to animate
    max-height: 50em;
    transition: max-height 1s;
    @media screen and (min-width:48em) {
        max-height: 20em;
        transition: max-height 0.5s;
    }
}

.accordionItem.is-collapsed {
    max-height: 0;
}

.no-js .accordionItem.is-collapsed {
    max-height: auto;
}

.animateIn {
    animation: accordionIn 0.45s normal ease-in-out both 1;
}

.animateOut {
    animation: accordionOut 0.45s alternate ease-in-out both 1;
}
@keyframes accordionIn {
    0% {
        transform       : scale(0.9) rotateX(-60deg);
        transform-origin: 50% 0;
        opacity         : 0;
    }

    100% {
        transform: scale(1);
        opacity  : 1;
    }
}
@keyframes accordionOut {
    0% {
        transform: scale(1);
        opacity  : 1;
    }

    100% {
        transform: scale(0.9) rotateX(-60deg);
        opacity  : 0;
    }
}

a.post-edit-link {
    bottom       : 10px;
    left         : 10px;
    position     : fixed;
    background   : rgba(200,200,200,0.6);
    padding      : 10px;
    border       : 2px solid rgba(30,60,100,0.3);
    border-radius: 3px;
    opacity      : 0.35;
    transition   : opacity 0.3s ease-in-out;

    &:hover {
        opacity: 1;
    }
}

.acceptance {
    color      : #565656;
    font-size  : 12px;
    font-weight: 500;
    padding    : 0.25em;
}

.extension-info {
    text-align: center;
    padding   : 0.25em;

    &:hover {
        & .extension-img {
            opacity: 1;
        }

        & .extension-text {
            color: #404040;
        }
    }

    & .extension-img {
        opacity   : 0.6;
        transition: all 0.2s ease-in-out;
    }

    & .extension-text {
        display   : block;
        color     : #ccc;
        padding   : 0.5em;
        transition: all 0.2s ease-in-out;
    }
}

.text-alert-red,
.text-alert-red:focus,
.text-alert-red:hover {
    background-color: #E26A6A;
}

.grid_item img {
    height: auto;
}
@media only screen and (max-width: 800px) {
    #gform_fields_1, #gform_fields_2 {
        columns        : 1 !important;
        -webkit-columns: 1 !important;
        -moz-columns   : 1 !important;
    }

    .gform_wrapper.gf_browser_chrome .gform_body {
        width: calc(100% - 15px);
    }
}
@media only screen and (min-width: 641px){
    .gform_wrapper .top_label input.medium, .gform_wrapper .top_label select.medium {
        width: calc(100% - 40px) !important;
    }
}

.gform_title{
    text-align: center;
}
.gform_footer{
    text-align: center;
}
li.gfield{
    -webkit-column-break-inside: avoid;
page-break-inside: avoid;
break-inside: avoid;
}
.gform_wrapper span.gform_drop_instructions {
    font-size: 1.5em;
}

#gform_fields_1, #gform_fields_2 {
  list-style: !important;
    columns        : 2;
    -webkit-columns: 2;
    -moz-columns   : 2;
    /*& #field_1_2{
    width: 33%;
    float: left;
    clear: none;
  }
  & #field_1_3{
    width: 33%;
    float: left;
    clear: none;
  }
  & #field_1_4{
    width: 33%;
    float: left;
    clear: none;
  }
  & #field_1_5{
    width: 50%;
    float: left;
    clear: none;
  }
  & #field_1_6{
    width: 50%;
    float: left;
    clear: none;
  }
  .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
          min-width: 220px;
  }*/
    #input_1_7, #input_2_7 {
        height   : 100px;
        width: calc(100% - 40px);
        border   : 1px solid rgba(0,160,228,0.9);
    }
}

.gform_wrapper .gform_fileupload_multifile .gform_drop_area {
    background: rgba(0,160,228,.2) !important;
    border    : 1px dashed rgba(0,160,228,0.9) !important;
}

.gform_wrapper input.large,
.gform_wrapper input.medium,
.gform_wrapper input.small,
.gform_wrapper select.large,
.gform_wrapper select.medium,
.gform_wrapper select.small {
    width: 90%;
}

.gfield > div.ginput_container > input.medium {
    width: 90%;
}

li.lang-item {
    list-style: none;
}

#polylang-2 {
    margin-top  : 11px;
    padding-left: 10px;

    & ul {
        margin : 0;
        padding: 0;
    }
}

li.lang-li-menu {
    &:hover {
        background: none !important;
    }
}

#polylang-3 {
    position: relative;
    top     : -8px;

    a {
        color      : #fff;
        font-weight: 600;
    }

    li.lang-item {
        float      : left;
        margin-left: 8px;
    }
}
